<template>
  <div class="col-sm-3">
    <router-link
      :to=" { name: 'SearchResults', params: { cityId: item.id, locale: $i18n.locale}}"
    >
      <a class='city-image' :style="{ 'background-image': 'url(' + item.  image + ')' }">
        <div class="city-data">
          <div class="centralizer">
            <div class="city-name">
              {{item.name}}
            </div>
            <div class="ribbon">17 {{$t('listings')}}</div>
          </div>
        </div>
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'city',
  props: ['item']
}

</script>

<style scoped>
.city-name{
  font-size: 22px;
}
</style>