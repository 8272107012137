<template>
  <div class="listing-item">  
    <a :class="['category-icon', `${getIcon(item.buisness.category.id)}`]"></a>
    <div class="listing-item-rating">{{item.buisness.rate}}</div>
    <router-link 
      :to="{ name: 'Business', params: { id: item.buisness.id, business: item, locale: $i18n.locale }}"
    >
      <a class="listing-item-link">
      <div class="listing-item-title-centralizer">
        <div class="listing-item-title">
          {{item.name}}
          <div v-if="item.buisness.allow_booking" class="ribbon"> Book Now </div>
        </div>
      </div>
      <img :alt="item.name" :src="item.buisness.media.length ? item.buisness.media[0].thumb : 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Circle_Davys-Grey_Solid.svg/1200px-Circle_Davys-Grey_Solid.svg.png'" />
    </a>
    </router-link>
    <div class="listing-item-data">
      <a class="listing-item-address" href="#">
        {{item.buisness.location.address}}
      </a>
      <div class="listing-item-excerpt">
        {{item.buisness.location.phone}}
      </div>
    </div>
    <div class="listing-category-name">
      <router-link
        :to="{ name: 'Reservation', params: {locale: $i18n.local, business: item.buisness} }"
      >
        <span class='fa fa-check button'></span>
      </router-link>
      <router-link
        :to=" { name: 'Reservation', params: {locale: $i18n.locale, business: item.buisness}}"
      >
        <span v-if="item.buisness.allow_booking" class='fa fa-book button'> </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { helpers } from '../../Helpers';

export default {
  name: 'SearchListingItem',
  
  mixins: [helpers],

  props: ['item']
}
</script>

<style scoped>
.listing-item-data::before{
  content: "\f041";
  font-size: 18px;
}
</style>