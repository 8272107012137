<template>
  <div>
    <div class="container-fluid pg-header">
      <div class="breadcrumbs text-left">
        <ul>
          <router-link
            :to="{path: '/'}"
          >
            <li><a>Home</a></li>
          </router-link>
          <li><a href="#">{{$t(title.name)}}</a></li>
        </ul>
      </div>
      <div class="pg-header-content">
        <h1 class="pg-title">
          {{$t(title.name)}}
        </h1>
        <h4 class="pg-subtitle">
          {{$t(title.subtitle)}}
        </h4>
      </div>
      <div :class="['pg-header-icon', `${title.icon}`]"></div>
    </div>
    <div class="">
      <!-- <div class=" page-intro">
        Choose a city to check local listings
      </div> -->
      <div class="container-fluid row text-center listing-results">
        <div class="results-count">
          <span class="counter">{{counter}}</span> {{$t('header.results')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['counter', 'currentRoute'],
  computed: {
    title() {
      if (this.$route.name == 'SearchResults') return {
        name: 'header.places',
        icon: 'icon-menu2',
        subtitle: 'header.subtitle',
        image: '',
      };
      else if(this.$route.name == 'AllProducts') return {
        name: 'header.all_products',
        icon: 'icon-cart',
        subtitle: 'header.subtitle2',
        image: '',
      };
      else return {
        name: 'header.all_cities',
        icon: 'icon-city',
        subtitle: 'header.subtitle3',
        image: '',
      };
    }
  }
}
</script>

<style scoped>
.results-count{
  margin: 10px 30px  !important;
  font-size: 20px;
}
.pg-header:after{
  background-image: unset;
}
</style>