<template>
  <div class="container-fluid" >
      <div v-if='searchResults.data' class="row" :class="width != 'sm' ? 'mx-9' : ''">
          <!-- <div v-if="currentRoute == 'AllProducts'" class=' row pg-content'>
            <Product v-for='item in allProducts.data.data' :key='item.id' :item='item'/>
          </div> -->
        <div class="col-12">
          <div class="row mt-5 mb-2 d-flex justify-content-between" :class="locale == 'ar' ? 'flex-row-reverse' : 'flex-row'">
            
              <div class="filter-header"
                :class="locale == 'ar' ? 'f-right' : 'f-left'"
              >
                {{$t('results')}} ({{searchResults.total}})
              </div>
              <div
                class=""
                :class="locale == 'ar'? 'f-left' : 'f-right'">
                <button class="btn-trans" @click='filter'>
                  <i class="fa fa-filter"></i>
                  {{$t('Filter')}}
                </button>
      
            </div>
          </div>
          <NoPlaces class='mt-5' v-if="searchResults.data.length == 0 || loading" :msg="$t('noResults')"/>
          <div v-else class="row " :class="locale =='ar' ? 'justify-content-end' : 'justify-content-start'">
            <div class=" col-md-10 col-12 text-center">
              <div v-for='item in searchResults.data' :key='item.id'>
                <!-- <Business :item='item' /> -->
                <ResultCard  :item='item' />
              </div>
            </div>
          </div>
        </div>
        <div class="separator-short"></div>
        <hr />
          <div class="col-12">
            <Pagination 
              v-if="searchResults.data.length > 0"
              :currentPage='page' 
              :showPage='showPage'
              :lastPage='lastPage'
            ></Pagination>
          </div>
        <div class="separator-short"></div>
      </div>
      <NoPlaces class='mt-5' v-else/>

      <div class="modal fade pb-9" tabindex="-1" id='filterModal'>
        <div class="mx-2 modal-dialog" :class='width == "sm" ? "w-100" : ["modal-lg", "mx-auto"]'>
          <div class="modal-content filter-contain">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <SideFilter :params='$route.params'/> 
            </div>
            <div class="modal-footer">
              <button type="button" class="btn-def" data-dismiss="modal">{{$t('Close')}}</button>
              <button type="button" class="btn-pri" data-dismiss="modal">{{$t('save')}}</button>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import Business from '@/components/Listings/Business.vue';
import SideFilter from '@/components/Listings/SideFilter.vue';
import Product from '@/components/Listings/Product.vue';
import Pagination from '@/components/misc/Pagination.vue';
import NoPlaces from '@/components/misc/NoPlaces.vue';
import City from '@/components/Listings/City.vue';
import Header from  '@/components/misc/Header.vue';
import ResultCard from  '@/components/Listings/resultCard.vue';



import { helpers } from '../Helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SearchResults',
  components: {
    Business,
    SideFilter,
    ResultCard,
    Product,
    Pagination,
    City,
    Header,
    NoPlaces,
  },
  mixins: [helpers],
  data() {
    return {
      text: '',
      cityId: '',
      categoryId: '',
      page: 1,
      booking: false,
    }
  },

  watch: {
    // '$route.params': {
    //   handler: function(params) {
    //     // this.initiateParams(params)
    //     // this.getPageData();
    //   },
    //   deep: true,
    //   immediate: true
    // },
    currentRoute: function(){
      this.getPageData()
    },
    locale: function(newLocale) {
      this.getPageData();
    }
  },

  mounted() {
    this.$loadScript('../../../js/bootstrap.bundle.js');
    this.initiateParams(this.$route.params);
    this.getPageData();
  },

  methods: {
    ...mapActions(['getSearchResults', 'getAllProducts',]),
    initiateParams(params) {
      if(localStorage.getItem('text') || localStorage.getItem('cat') || localStorage.getItem('city') || localStorage.getItem('book')) {
        if(localStorage.getItem('text')) {
          this.text = localStorage.getItem('text') != undefined && localStorage.getItem('text') != 'undefined' ? localStorage.getItem('text') : '';
        }
        if(localStorage.getItem('cat')) {
          this.categoryId = localStorage.getItem('cat') != undefined && localStorage.getItem('cat') != 'undefined' ? localStorage.getItem('cat') : '';
        }
        if(localStorage.getItem('city')) {
          this.cityId = localStorage.getItem('city') != undefined && localStorage.getItem('city') != 'undefined' ? localStorage.getItem('city') : '';
        }
        if(localStorage.getItem('book')) {
          this.booking = true;
        }
      }else {
        this.text = params.text;
        this.cityId = params.cityId;
        this.categoryId = params.categoryId;
        this.page = 1;
      }
    },
    showPage(page) {
      if(page != this.page) {
        console.log(page, this.page)
        this.page = page;
        this.getPageData();
      }
    },
    getPageData() {
      if(this.currentRoute == 'SearchResults') {
        this.getSearchResults({
          text: this.text,
          cityId : this.cityId,
          categoryId: this.categoryId,
          page: this.page,
          locale: this.$i18n.locale
        }).then()
          .catch(err => console.log(err));
      }else if(this.currentRoute == 'AllProducts'){
        this.getAllProducts({
          lang: this.$i18n.locale,
          page: this.page
        }).then()
          .catch(err => console.log(err))
      }else if(this.currentRoute == 'Cities'){
        
      }
    },
    filter() {
      $('#filterModal').modal('show')
    }
  },

  computed: {
    ...mapGetters([ 'searchResults', 'allProducts', 'cities', 'width', 'loading']),
    locale() {
      return this.$i18n.locale;
    },
    currentRoute() {
      if (this.$route.name == 'SearchResults') return 'SearchResults';
      else if(this.$route.name == 'AllProducts') return 'AllProducts';
      else if(this.$route.name == 'AllCities') return 'Cities';
    },
    lastPage() {
      if (this.$route.name == 'SearchResults') return this.searchResults.last_page;
      else if(this.$route.name == 'AllProducts') return this.allProducts.data.last_page;
      else return 1;
    },
    listingsCounter() {
      if (this.$route.name == 'SearchResults' && this.searchResults.total ) return this.searchResults.total;
      else if(this.$route.name == 'AllProducts' && this.allProducts.data) return this.allProducts.data.total;
      else return this.cities.length;
    }
  }
}

</script>

<style scoped>
.cities{
  margin-top: 90px;
} 
</style>