<template>
  <div class="col-sm-3">
    <div class="listing-item">
      <!-- <a href="#" :class="['category-icon', getIcon(item.category_id)]" style="opacity: 1;"></a> -->
      <!-- <div class="listing-item-rating" style="opacity: 1;">{{item.buisness.rate}}</div> -->
      <a href="#" class="listing-item-link">
        <div class="listing-item-title-centralizer">
          <div class="listing-item-title">
            <div class="ribbon"> {{$t('form')}} € {{item.price}} </div>
            <div class="ribbon">{{$t('to')}} € {{item.price_after_sale}} </div>
          </div>
        </div>
        <img alt="" :src="item.photo ? item.photo.thumb : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEWAgIB7e3vCwsLIyMjPz8/V1dV4eHjc3Nzj4+PMzMzt7e1zc3O/v7/////39/fx8fHg4ODJbms7AAABaElEQVR4nO3dSWrDABBFQcm2PMkZ7n/aaJFNiCEmZOBJVSf4b9/Qw9Pzy/V8Pp8W0+Lw7rL/YPd9+y9dDo+YHnH65DpM8/Ge8S/dXfAz5tswHYc1G3cK65bCg8I2hX0K+xT2KexT2LcUXsb/HvGrlsK9wjaFfQr7FPYp7FPYp7BPYZ/CPoV9CvsU9insU9insE9h3yYKdwrbFPYp7FPYp7BPYZ/CPoV9CvsU9insU9insE9hn8I+hX0K+xT2KexT2LeJiyGFcQr7FPYp7FPYp7BPYZ/CPoV9CvsU9insU9insE9hn8I+hX0K+xT2baJw/T9K1v9nRmGcwj6FfQr7FPYp7FPYp7BPYZ/CPoV9CvsU9insU9insE9hn8K+pXBS2KawT2Gfwj6FfQr7FPYp7FPYp7BPYZ/CPoV9CvsU9insU9insG8ThSeFbQr7FPYp7FPYp7BPYZ/Cvm0UzuOazbfhetut2e31DZx9EnazzT4gAAAAAElFTkSuQmCC '">
      <div class="listing-black-overlay"></div></a>
      <div class="listing-item-data">
        <a class="listing-item-address" href="#">
          {{item.name}}
        </a>
        <div class="listing-item-excerpt">
          {{item.description}}
        </div>
      </div>
      <div class="listing-category-name">
        <!-- <a href="#">{{htmlDecode(item.buisness.category.name)}}</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { helpers } from '../../Helpers';

export default {
  props: ['item'],
  mixins: [ helpers ]
}
</script>